/* *,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body,
#root,
.App {
	min-height: 100dvh;
	height: 100%;
} */

.fa-faster {
	--fa-animation-duration: 1s;
}

@media screen and (min-width: 1200px) {
	body {
		overflow: hidden;
	}
}

.animated {
	-webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
		-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
		max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
	transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
		transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,
		max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.highcharts-credits {
	display: none;
}

img {
	max-width: 100%;
}

.customLegendLight {
	color: #0064FF;
}

.customLegendLight:hover {
	color: #00289B;
}

.customLegendDark {
	color: #98C0FF;
}

.customLegendDark:hover {
	color: #fff;
}

.css-azlj9b {
	box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
}

.print-bar a {
	font-size: 1.125rem!important;
	font-weight: 400!important;
	text-transform: initial!important;
	line-height: 1.4!important;
	min-width: 64px!important;
	background-color: #0064FF!important;
	padding: 6px 16px!important;
	border-radius: 10px!important;
}

.print-bar a:hover, .print-bar a:active {
	color: #fff!important;
	background-color: #00289B!important;
}
.ql-align-center {
	text-align: center;
}
.ql-align-right {
	text-align: right;
}
.ql-align-justify {
	text-align: justify;
}
.ql-formula {
	box-sizing: border-box;
}