.quill {
    background-color: #fff;
    border-radius: 20px;
}
.ql-container {
    font-size: 1rem;
    min-height: 130px;
}
.ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid #ddd;
    font-family: Rubik, "Helvetica Neue", Arial, sans-serif;
    padding: 14px 14px 14px 15px;
}
.ql-editor {
    padding: 14px 14px 14px 15px;
}
.ql-container.ql-snow {
    border: none;
}
.ql-snow .ql-picker {
    font-size: 1rem;
    font-weight: 400;
}
.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #00289b;
}
.quill > .ql-toolbar:first-child {
    display: none !important;
}
