.react-datepicker {
    font-family: Rubik,"Helvetica Neue",Arial,sans-serif;
    border: none;
    font-size: 1rem;
    background-color: transparent;
    border-radius: 15px;
}
.dark-mode.react-datepicker-popper {
    z-index: 999;
    background-color: #0064FF;
    border-radius: 15px;
}
.dark-mode .react-datepicker__day-name, .dark-mode .react-datepicker__current-month, .dark-mode .react-datepicker-time__header, .dark-mode .react-datepicker-year-header, .dark-mode .react-datepicker__day  {
    color: #efefef;
}
.react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
}
.react-datepicker__month {
    background-color: transparent;
}
.react-datepicker-popper {
    z-index: 999;
    background-color: #98C0FF;
    border-radius: 15px;
    transform: none!important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: #00289B;
    font-weight: normal;
    font-size: 1.125rem;
}
.react-datepicker__day {
    color: #00289B;
    font-weight: normal;
    /*width: 2rem;*/
    /*line-height: 2rem;*/
}
.react-datepicker__day--outside-month, .dark-mode .react-datepicker__day--outside-month {
    color: #6481A6;
}
.react-datepicker__day-name {
    color: #00289B;
    font-weight: 600;
}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
    background-color: rgba(0, 0, 0, 0.08);
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    background-color: rgba(0, 0, 0, 0.08);
}
.dark-mode .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .dark-mode .react-datepicker__month-text--in-range, .dark-mode .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .dark-mode .react-datepicker__day--selected{
    background-color: #00289B;
}
.dark-mode .react-datepicker__day--in-range {
    background-color: #00289B;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__day--selected{
    background-color: #D7E6FF;
}
.react-datepicker__day--in-range {
    background-color: #D7E6FF;
}
.react-datepicker__day-name {
    /*width: 2rem;*/
}
.react-datepicker__month-select, .react-datepicker__year-select {
    color: #0064FF;
    border: 1px solid #fff;
    background-color: #fff;
    border-radius: 5px;
    padding: 4px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    display: none;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border: none;
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before, .react-datepicker__navigation:hover *::before {
    border-color: #fff;
}
.react-datepicker__navigation:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 50%;
}
.react-datepicker__navigation-icon--next {
    left: 1px;
}
.react-datepicker__navigation-icon {
    top: 0;
}
.react-datepicker__navigation-icon--previous {
    right: 1px;
}
.react-datepicker__month-dropdown-container--select {
    margin: 0 10px;
}
.react-datepicker__header__dropdown {
    margin: 10px 0;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    margin: 0.35rem;
}
.MuiTextField-root {
    border-radius: 15px!important;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
    border: none;
}
.react-datepicker__tab-loop {
    position: relative;
}
.react-datepicker__time-list{
    background-color: #98C0FF;
}
.react-datepicker__time-list-item {
    color: #00289B;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #D7E6FF;
    color: #00289B;
    font-weight: 400;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background-color: rgba(0, 0, 0, 0.08);
}
.react-datepicker__time-list::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.react-datepicker__time-list {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
