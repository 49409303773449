*,
*:before,
*:after {
    box-sizing: border-box;
    outline: 0;
}
table {
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
}
table tr {
    margin: 0;
    padding: 0;
    border: none;
}
table td {
    margin: 0;
    padding: 0;
    border: none;
}
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
@media print {
    html,
    body {
        height: 100%;
    }
}
@page {
    size: A4;
    margin-top: 40px;
    margin-bottom: 40px;
}
.lines-action-plan {
    padding-inline-start:20px;
    width: 95%;
    padding-bottom: 20px;
}
.lines-action-plan.without-numbers {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.lines-action-plan li {
    border-bottom: 1px solid #888888;
    padding-top: 10px;
}
.lines-action-plan.without-numbers li {
    height: 25px;
}
.lines-action-plan li:first-child {
    padding-top: 0;
}
.mb-100 {
    margin-bottom: 100px;
}
.mb-16 {
    margin-bottom: 20mm;
}
.mb-10 {
    margin-bottom: 10mm!important;
}
.mt-10 {
    margin-top: 10mm!important;
}
.mt-5 {
    margin-top: 5mm!important;
}
.cover-bg {
    /* background-color: #002D96; */
    /* padding: 20mm 20mm 20mm 30mm; */
    position: relative;
    margin-bottom: 39pt;
}
@media screen {
    .cover-bg .cover-page {
        background-color: #EBF3FF;
        border-radius: 20pt;
    }
}
.wrapper-cover {
    color: #000000;
    /* position: absolute;
    top: 18mm;
    bottom: 18mm;
    left: 30mm;
    right: 20mm;
    margin: auto;
    margin-top: 0 !important; */
    page-break-after: always;
    max-width: 990px;
    margin: auto;
}
@media screen {
    .wrapper-cover {
        padding: 40px;
    }
}
@media print {
    .wrapper-cover {
        color: #000;
        padding: 40px;
    }
}
.wrapper-cover .title {
    padding: 22mm 0 4mm 0;
}
.wrapper-cover h1 {
    font-weight: 500;
    font-size: 24pt;
    color: #000000;
    margin: 0 0 20px 0;
    line-height: 24pt;
}
.wrapper-cover h1 span {
    font-size: 21pt;
}
.wrapper-cover h2, .section-despre .subtitleH1 {
    font-weight: 400;
    font-size: 17pt;
    color: #000000;
    margin: 0;
}
.wrapper-cover h4 {
    font-weight: 600;
    color: #000000;
}
.wrapper-cover h3 {
    font-weight: 600;
    font-size: 10pt;
}
.wrapper-page {
    color: #000;
    page-break-after: always;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30mm;
    right: 20mm;
    margin: auto;
    margin-top: 0 !important;
}
.wrapper-page h1 {
    font-weight: 500;
    font-size: 21pt;
    margin: 0;
    line-height: 24pt;
    page-break-inside: avoid;
}
.wrapper-page h2 {
    font-weight: 400;
    font-size: 17pt;
    line-height: 24pt;
    margin: 0;
    page-break-inside: avoid;
}
.cover-logo {
    width: 40%;
    max-width: 228pt;
    height: auto;
    display: block;
}
@media screen {
    .print {
        display: none !important;
    }
}
@media print {
    .screen {
        display: none !important;
    }
}
.cover-foo {
    font-weight: 400;
    font-size: 7pt;
    margin-top: 40px;
    color: #000000;
    /* margin-bottom: 20px; */
    /* position: absolute;
    bottom: 0;
    left: 0; */
}
@media screen {
    .cover-img {
        float: right;
        width: 40%;
        max-width: 360px;
    }
}
@media print {
    .cover-img {
        display: block;
        width: 100%;
        max-width: 50%;
        margin: 40px auto 0;
        /* position: absolute;
				bottom: 35pt;
				left: 0; */
    }
}
.section-despre {
    font-size: 11pt;
    line-height: 15pt;
    font-weight: 400;
}
.section-despre li {
    margin: 15pt 0 11pt 0;
}
.section-despre p {
    margin: 15pt 0 11pt 0;
    font-weight: 400;
    font-size: 11pt;
    line-height: 15pt;
}
.section-despre p.table-note {
    font-size: 9.2pt;
    line-height: 13pt;
}

.section-despre h1 {
    font-weight: 500;
    font-size: 21pt;
    margin: 0 0 13.5mm 0;
    line-height: 24pt;
    page-break-inside: avoid;
    page-break-after: avoid;
}

.section-despre h1 .subtitle {
    font-weight: 500;
    font-size: 14pt;
    margin: 5pt 0 0 0;
    line-height: 14pt;
    page-break-after: avoid;
    page-break-inside: avoid;
}

.section-despre h3 {
    font-weight: 700;
    font-size: 11.2pt;
    line-height: 13pt;
    margin: 0 0 6mm;
    text-transform: uppercase;
    page-break-inside: avoid;
    page-break-after: avoid;
}

.section-despre h5 {
    font-weight: 700;
    font-size: 9.5pt;
    line-height: 11pt;
    margin: 0 0 6mm;
    page-break-inside: avoid;
    page-break-after: avoid;
}
.subsection.margin-top {
    margin-top: 16mm;
}

.subsection ul {
    list-style: square;
    padding-left: 12pt;
}

.subsection ul li {
    margin: 0;
    padding: 0 0 0 5pt;
}

.subsection p {
    page-break-inside: avoid !important;
}

.subsection-content {
    padding-left: 72pt;
    display: flow-root;
}

.fix-print {
    /* overflow: auto; */
    display: flow-root;
}

.section-evaluare .subsection {
    /* color: #5E666F; */
    position: relative;
    /* width: 160mm; */
    margin: 0;
}

.section-evaluare .subsection.marked {
    padding: 5mm;
    /* color: #000; */
}

.subsection {
    padding: 5mm;
    position: relative;
}

.subsection p {
    page-break-inside: avoid;
}

.subsection.marked {
    page-break-inside: avoid;
}

.subsection.marked:before {
    display: block;
    content: '';
    border: 2pt solid #393231;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.subsection.marked.insuficient-literatie:before {
    border-color: #C80000;
}

.subsection.marked.expert-literatie:before {
    border-color: #00952C;
}

.subsection.marked.avansat-literatie:before {
    border-color: #41C828;
}

.subsection.marked.competent-literatie:before {
    border-color: #f9db00;
}

.subsection.marked.intermediar-literatie:before {
    border-color: #FFA500;
}

.subsection.marked.satisfacator-literatie:before {
    border-color: #FF5A00;
}

.subsection.marked.expert:before {
    border-color: #00952C;
}

.subsection.marked.avansat:before {
    border-color: #41C828;
}

.subsection.marked.competent:before {
    border-color: #82E164;
}

.subsection.marked.intermediar:before {
    border-color: #FFA500;
}

.subsection.marked.satisfacator:before {
    border-color: #FF5A00;
}

.subsection.marked.insuficient:before {
    border-color: #C80000;
}

.subsection.marked.ld-nivel-baza:before {
    border-color: #fc8900;
}
.subsection.marked.ld-intermediar:before {
    border-color: #f9db00;
}
.subsection.marked.ld-avansat:before {
    border-color: #07ff00;
}
.subsection.marked.ld-specializat:before {
    border-color: #0000ff;
}

.subsection h3 {
    break-after: avoid;
    margin: 0;
    
    font-weight: 700;
    font-size: 11.2pt;
    line-height: 13pt;
    text-transform: uppercase;
    page-break-inside: avoid;
    width: auto;
}

.subsection-content.subsection-content-levels {
    padding-left: 0;
    margin-bottom: 6.5mm;
}

.chart {
    position: relative;
    width: 100%;
    transform: scale(0.925);
    display: flow-root;
    margin-top: 13.5mm;
}

@media print {
    .chart,
    .tabel-note {
        transform: scale(0.925);
        display: block;
    }
}
.chart .chart-bg {
    width: 100%;
    height: auto;
    display: none;
    position: relative;
}

.chart .chart-bg svg * {
    width: 100%;
    height: 100%;
}

.chart .chart-bg.active {
    display: block;
}

.chart .unit-wrapper {
    position: relative;
    height: 10pt;
    margin: 0 2pt;
}

.chart .unit {
    position: absolute;
    
    font-size: 8.6pt;
    font-weight: 200;
    top: 0;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.chart .unit:nth-child(1) {
    left: 0;
}

.chart .unit:nth-child(2) {
    left: 5%;
}

.chart .unit:nth-child(3) {
    left: 10%;
}

.chart .unit:nth-child(4) {
    left: 15%;
}

.chart .unit:nth-child(5) {
    left: 20%;
}

.chart .unit:nth-child(6) {
    left: 25%;
}

.chart .unit:nth-child(7) {
    left: 30%;
}

.chart .unit:nth-child(8) {
    left: 35%;
}

.chart .unit:nth-child(9) {
    left: 40%;
}

.chart .unit:nth-child(10) {
    left: 45%;
}

.chart .unit:nth-child(11) {
    left: 50%;
}

.chart .unit:nth-child(12) {
    left: 55%;
}

.chart .unit:nth-child(13) {
    left: 60%;
}

.chart .unit:nth-child(14) {
    left: 65%;
}

.chart .unit:nth-child(15) {
    left: 70%;
}

.chart .unit:nth-child(16) {
    left: 75%;
}

.chart .unit:nth-child(17) {
    left: 80%;
}

.chart .unit:nth-child(18) {
    left: 85%;
}

.chart .unit:nth-child(19) {
    left: 90%;
}

.chart .unit:nth-child(20) {
    left: 95%;
}

.chart .unit:nth-child(21) {
    right: 0;
    -webkit-transform: translate(50%, 0);
    -moz-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    transform: translate(50%, 0);
}

.chart .unit strong {
    font-size: 8.6pt;
    font-weight: 700;
}

.chart .unit .number {
    text-align: center;
}

.chart .chart-note {
    text-align: center;
    position: absolute;
    top: 2pt;
}

.chart .chart-note .border {
    height: 4.5mm;
    width: 100%;
    border: 1.2pt solid #000;
    border-top: none;
    margin-bottom: 4pt;
}

.chart .chart-note .name {
    
    font-size: 8pt;
    font-weight: 400;
    line-height: 9.5pt;
    margin-bottom: 2pt;
    white-space: nowrap;
    text-align: center;
}

.chart .chart-note .values {
    
    font-size: 6pt;
    font-weight: 300;
    line-height: 9.5pt;
    white-space: nowrap;
    text-align: center;
}

.chart .chart-note.nivel-baza-ld {
    width: 35%;
    margin: 7pt 0 0 0;
}
.chart .chart-note.intermediar-ld {
    width: 30%;
    margin: 7pt 0 0 0;
    left: 35%;
}
.chart .chart-note.avansat-ld {
    width: 30%;
    margin: 7pt 0 0 0;
    left: 65%;
}
.chart .chart-note.specializat-ld {
    width: 5%;
    margin: 7pt 0 0 0;
    left: 95%;
}

.chart .chart-note.insuficient {
    width: 10%;
    margin: 7pt 0 0 0;
}

.chart .chart-note.satisfacator {
    width: 20%;
    margin: 7pt 0 0 0;
    left: 10%;
}

.chart .chart-note.intermediar {
    width: 30%;
    margin: 7pt 0 0 0;
    left: 30%;
}

.chart .chart-note.competent {
    width: 20%;
    margin: 7pt 0 0 0;
    left: 60%;
}

.chart .chart-note.avansat {
    width: 15%;
    margin: 7pt 0 0 0;
    left: 80%;
}

.chart .chart-note.expert {
    width: 5%;
    margin: 7pt 0 0 0;
    left: 95%;
}

.chart .chart-note-wrapper {
    position: relative;
    height: 12mm;
    margin: 0 0pt;
}

.chart .chart-note-wrapper.even {
    margin-top: -1mm;
}

.chart .nota-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 45mm;
}

.chart .nota {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: -6.35mm;
    z-index: 10;
}

.chart .nota .label {
    
    font-size: 9pt;
    font-weight: 400;
    margin-bottom: 2pt;
}

.chart .nota .value {
    width: 12mm;
    height: 12.5mm;
    margin: 0 auto;
    /* background-image: url("/images/reports/nota.png"); */
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
    background-color: transparent;
    text-align: center;
    
    font-size: 11pt;
    font-weight: 700;
    color: #fff;
    padding: 7pt 0 0 0;
    position: relative;
}

@media print {
    .chart .nota .value {
        color: #fff;
        background: none;
    }
}

.chart .nota .value svg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.score-horizontal {
    background-color: #C80000;
    fill: #C80000;
    height: 5mm;
    margin-left: 2px !important;
    top: 30px;
    position: relative;
}

.score-horizontal-value {
    /* right: 100%; */
    
    font-size: 9pt;
    font-weight: 400;
    color: #fff;
    position: absolute;
    right: 0px;
    top: 1mm;
    margin-right: 10px;
    text-align: right;
    z-index: 2;
}

.score-horizontal svg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.score-horizontal-expert {
    background-color: #00952C;
    fill: #00952C;
}

.score-horizontal-avansat {
    background-color: #41C828;
    fill: #41C828;
}

.score-horizontal-competent {
    background-color: #82E164;
    fill: #82E164;
}

.score-horizontal-intermediar {
    background-color: #FFA500;
    fill: #FFA500;
}

.score-horizontal-satisfacator {
    background-color: #FF5A00;
    fill: #FF5A00;
}

.score-horizontal-insuficient {
    background-color: #C80000;
    fill: #C80000;
}
.score-horizontal-competent-literatie {
    background-color: #F9DB00;
    fill: #F9DB00;
}

.title-nivel {
    /* margin: 0 0 6mm; */
    table-layout: auto;
    border-collapse: collapse;
    width: auto;
}

.title-nivel td, .title-nivel .td {
    vertical-align: middle;
    margin: 5mm 0;
    border: none;
    
    font-weight: 700;
    font-size: 11.2pt;
    line-height: 13pt;
    text-transform: uppercase;
    page-break-inside: avoid;
    width: auto;
    padding: 1pt 2pt;
}

.title-nivel .square {
    width: 4mm;
    height: 4mm;
    margin: 0 3pt 0 0;
    display: inline-block;
    position: relative;
    top: -1px;
    vertical-align: middle;
}

.title-nivel tr:first-child td {
    white-space: nowrap;
}

.title-nivel tr:first-child td:first-child {
    min-width: 100px;
}

.title-nivel .subsection-content {
    padding-left: 0;
    font-size: 9pt;
    line-height: 13pt;
    font-weight: 400;
    text-transform: none;
    margin-top: 10pt;
}

.title-nivel.expert .square {
    background-color: #00952C;
    fill: #00952C;
}

.title-nivel.avansat .square {
    background-color: #41C828;
    fill: #41C828;
}

.title-nivel.competent .square {
    background-color: #82E164;
    fill: #82E164;
}

.title-nivel.intermediar .square {
    background-color: #FFA500;
    fill: #FFA500;
}

.title-nivel.satisfacator .square {
    background-color: #FF5A00;
    fill: #FF5A00;
}

.title-nivel.insuficient .square {
    background-color: #C80000;
    fill: #C80000;
}

.title-nivel.ld-nivel-baza .square {
    background-color: #fc8900;
    fill: #fc8900;
}
.title-nivel.ld-intermediar .square {
    background-color: #f9db00;
    fill: #f9db00;
}
.title-nivel.ld-avansat .square {
    background-color: #07ff00;
    fill: #07ff00;
}
.title-nivel.ld-specializat .square {
    background-color: #0000ff;
    fill: #0000ff;
}

.legend {
    display: inline-block;
    /* margin: 0 0 0 3px; */
    position: relative;
    padding-left: 15pt;
}

.legend .text {
    display: inline-block;
}

.legend.expert .square {
    background-color: #00952C;
    fill: #00952C;
}

.legend.avansat .square {
    background-color: #41C828;
    fill: #41C828;
}

.legend.competent .square {
    background-color: #82E164;
    fill: #82E164;
}

.legend.intermediar .square {
    background-color: #FFA500;
    fill: #FFA500;
}

.legend.satisfacator .square {
    background-color: #FF5A00;
    fill: #FF5A00;
}

.legend.insuficient .square {
    background-color: #C80000;
    fill: #C80000;
}

.legend.ld-nivel-baza .square {
    background-color: #fc8900;
    fill: #fc8900;
}
.legend.ld-intermediar .square {
    background-color: #f9db00;
    fill: #f9db00;
}
.legend.ld-avansat .square {
    background-color: #07ff00;
    fill: #07ff00;
}
.legend.ld-specializat .square {
    background-color: #0000ff;
    fill: #0000ff;
}

.legend .square {
    width: 4mm;
    height: 4mm;
    left: 0;
    position: relative;
    display: inline-block;
    margin-right: 3pt;
    top: -1px;
    vertical-align: middle;
}

.tabel-note {
    margin: 0 0 12pt 0;
    padding: 0;
    table-layout: fixed;
    border-collapse: collapse;
    position: relative;
    min-width: 500px;
    width: 97%;
}

.tabel-note thead th {
    margin: 0;
    padding: 0;
}

.tabel-note tbody tr {
    margin: 0;
    padding: 0;
    border-bottom: 0.2pt solid #9A9999;
}

.tabel-note tbody tr:nth-child(odd) {
    background-color: #ECECEC;
}

.tabel-note tbody tr td {
    margin: 0;
    padding: 5pt 0;
    
    position: relative;
}

.tabel-note tbody tr.capitol td {
    font-size: 9.5pt;
    line-height: 10.5pt;
    font-weight: 700;
    position: relative;
    padding: 5px 1%;
}

.tabel-note tbody tr.capitol td .bar-group .number {
    font-size: 7pt;
    line-height: 7pt;
    font-weight: 700;
}

.tabel-note tbody tr.subcapitol td {
    font-size: 8.5pt;
    line-height: 9.5pt;
    font-weight: 400;
    position: relative;
    padding: 5px 2%;
}

.tabel-note tbody tr.subcapitol td .bar-group .number {
    font-size: 7pt;
    line-height: 7pt;
    font-weight: 400;
}

.tabel-note .col-values {
    padding-bottom: 20pt;
}

.tabel-note .unit-wrapper {
    position: relative;
    height: 10pt;
}

.tabel-note .unit {
    position: absolute;
    
    font-size: 6pt;
    font-weight: 200;
    top: 0;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.tabel-note .unit:nth-child(1) {
    left: 0;
}

.tabel-note .unit:nth-child(2) {
    left: 5%;
}

.tabel-note .unit:nth-child(3) {
    left: 9.7%;
}

.tabel-note .unit:nth-child(4) {
    left: 15%;
}

.tabel-note .unit:nth-child(5) {
    left: 20%;
}

.tabel-note .unit:nth-child(6) {
    left: 25%;
}

.tabel-note .unit:nth-child(7) {
    left: 30%;
}

.tabel-note .unit:nth-child(8) {
    left: 35%;
}

.tabel-note .unit:nth-child(9) {
    left: 40%;
}

.tabel-note .unit:nth-child(10) {
    left: 45%;
}

.tabel-note .unit:nth-child(11) {
    left: 50%;
}

.tabel-note .unit:nth-child(12) {
    left: 55%;
}

.tabel-note .unit:nth-child(13) {
    left: 60%;
}

.tabel-note .unit:nth-child(14) {
    left: 65%;
}

.tabel-note .unit:nth-child(15) {
    left: 70%;
}

.tabel-note .unit:nth-child(16) {
    left: 75%;
}

.tabel-note .unit:nth-child(17) {
    left: 80%;
}

.tabel-note .unit:nth-child(18) {
    left: 85%;
}

.tabel-note .unit:nth-child(19) {
    left: 90%;
}

.tabel-note .unit:nth-child(20) {
    left: 95%;
}

.tabel-note .unit:nth-child(21) {
    right: 0;
    -webkit-transform: translate(50%, 0);
    -moz-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    transform: translate(50%, 0);
}

.tabel-note .unit strong {
    font-weight: 700;
}

.tabel-note .unit .number {
    text-align: center;
}

.tabel-note .bar-group {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.tabel-note .bar-group .bar {
    display: block;
    height: 2.5mm;
    background-color: #000;
}

.tabel-note .bar-group .bar.expert {
    background-color: #00952C;
}

.tabel-note .bar-group .bar.avansat {
    background-color: #41C828;
}

.tabel-note .bar-group .bar.competent {
    background-color: #82E164;
}

.tabel-note .bar-group .bar.intermediar {
    background-color: #FFA500;
}

.tabel-note .bar-group .bar.satisfacator {
    background-color: #FF5A00;
}

.tabel-note .bar-group .bar.insuficient {
    background-color: #C80000;
}

.tabel-note .bar-group .bar.ld-nivel-baza {
    background-color: #fc8900;
}
.tabel-note .bar-group .bar.ld-intermediar {
    background-color: #f9db00;
}
.tabel-note .bar-group .bar.ld-avansat {
    background-color: #07ff00;
}
.tabel-note .bar-group .bar.ld-specializat {
    background-color: #0000ff;
}
.tabel-note .bar-group .bar.expert-literatie {
    background-color: #00952C;
}
.tabel-note .bar-group .bar.insuficient-literatie {
    background-color: #C80000;
}
.tabel-note .bar-group .bar.competent-literatie {
    background-color: #f9db00;
}

.svg-print {
    width: 100%;
    height: 100%;
    display: block;
}

.tabel-note .bar-group .bar.expert svg {
    fill: #00952C;
}

.tabel-note .bar-group .bar.avansat svg {
    fill: #41C828;
}

.tabel-note .bar-group .bar.competent svg {
    fill: #82E164;
}

.tabel-note .bar-group .bar.intermediar svg {
    fill: #FFA500;
}

.tabel-note .bar-group .bar.satisfacator svg {
    fill: #FF5A00;
}

.tabel-note .bar-group .bar.insuficient svg {
    fill: #C80000;
}

.tabel-note .bar-group .bar.ld-nivel-baza svg {
    fill: #fc8900;
}
.tabel-note .bar-group .bar.ld-intermediar svg {
    fill: #f9db00;
}
.tabel-note .bar-group .bar.ld-avansat svg {
    fill: #07ff00;
}
.tabel-note .bar-group .bar.ld-specializat svg {
    fill: #0000ff;
}

.tabel-note .bar-group .number {
    position: absolute;
    right: -10pt;
    top: 0;
}

.tabel-note .legenda {
    background-color: #fff !important;
    border-bottom: none !important;
}

.tabel-note .legenda .chart-tabel {
    width: 100.7%;
    height: auto;
    position: relative;
    margin-left: -0.5mm;
}

.tabel-note .col-legend .chart-note {
    text-align: center;
    position: absolute;
    top: 0;
}

.tabel-note .col-legend .chart-note .border {
    height: 4mm;
    width: 100%;
    border: 1.2pt solid #000;
    border-top: none;
    margin-bottom: 4pt;
}

.tabel-note .col-legend .chart-note .name {
    
    font-size: 7pt;
    font-weight: 400;
    line-height: 9pt;
    margin-bottom: 0;
    white-space: nowrap;
    text-align: center;
}

.tabel-note .col-legend .chart-note .values {
    
    font-size: 5pt;
    font-weight: 400;
    line-height: 7pt;
    white-space: nowrap;
    text-align: center;
}

.tabel-note .col-legend .chart-note.insuficient {
    width: 10%;
    margin: 7pt 0 0 0;
}

.tabel-note .col-legend .chart-note.satisfacator {
    width: 20%;
    margin: 7pt 0 0 0;
    left: 10%;
}

.tabel-note .col-legend .chart-note.intermediar {
    width: 30%;
    margin: 7pt 0 0 0;
    left: 30%;
}

.tabel-note .col-legend .chart-note.competent {
    width: 20%;
    margin: 7pt 0 0 0;
    left: 60%;
}

.tabel-note .col-legend .chart-note.avansat {
    width: 15%;
    margin: 7pt 0 0 0;
    left: 80%;
}

.tabel-note .col-legend .chart-note.expert {
    width: 5%;
    margin: 7pt 0 0 0;
    left: 95%;
}

.tabel-note .col-legend .chart-note.nivel-baza-ld {
    width: 35%;
    margin: 7pt 0 0 0;
}
.tabel-note .col-legend .chart-note.intermediar-ld {
    width: 30%;
    margin: 7pt 0 0 0;
    left: 35%;
}
.tabel-note .col-legend .chart-note.avansat-ld {
    width: 30%;
    margin: 7pt 0 0 0;
    left: 65%;
}
.tabel-note .col-legend .chart-note.specializat-ld {
    width: 5%;
    margin: 7pt 0 0 0;
    left: 95%;
}

.tabel-note .col-legend .chart-note-wrapper {
    position: relative;
    width: 100%;
    height: 12mm;
    z-index: 1;
}

.tabel-note .col-legend .chart-note-wrapper.even {
    margin-top: -3mm;
}

.tabel-note .col-capitol {
    width: 45.4mm;
}

.tabel-note .mark-group {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    left: 45mm;
    right: 0;
}

.tabel-note .mark-line {
    width: 0.8pt;
    background-color: #9A9999;
    position: absolute;
    top: 7mm;
    bottom: 31mm;
    z-index: 0;
    /* background-image: url("../images/vline.png");
	 background-repeat: no-repeat !important;
	 background-position: center center !important;
	 background-size: contain !important;*/
}

.tabel-note .mark-line.mark-0 {
    left: 1pt;
}

.tabel-note .mark-line.mark-10 {
    left: 10%;
}

.tabel-note .mark-line.mark-25 {
    left: 25%;
}

.tabel-note .mark-line.mark-30 {
    left: 30%;
}

.tabel-note .mark-line.mark-60 {
    left: 60%;
}

.tabel-note .mark-line.mark-80 {
    left: 80%;
}

.tabel-note .mark-line.mark-95 {
    left: 95%;
}

.tabel-note .mark-line.mark-100 {
    right: 0;
}

.tabel-note .mark-line-ld {
    width: 0.8pt;
    background-color: #9A9999;
    position: absolute;
    top: 7mm;
    bottom: 31mm;
    z-index: 0;
}

.tabel-note .mark-line-ld.mark-0 {
    left: 1pt;
}
.tabel-note .mark-line-ld.mark-35 {
    left: 35%;
}
.tabel-note .mark-line-ld.mark-65 {
    left: 65%;
}
.tabel-note .mark-line-ld.mark-95 {
    left: 95%;
}
.tabel-note .mark-line-ld.mark-100 {
    right: 0;
}

.next-upgrade {
    text-align: left;
    position: absolute;
    left: 100%;
    top: 6mm;
    
    font-size: 7.5pt;
    line-height: 9.5pt;
    font-weight: 300;
    padding-left: 5mm;
    white-space: nowrap;
    margin-left: -4.6mm;
}

.next-upgrade .upgrade-name {
    font-weight: 400;
}

.next-upgrade .arrow-right {
    width: 4.3mm;
    left: 0;
    position: absolute;
    top: 0.7mm;
}

.insuficient .next-upgrade {
    margin-left: 0;
}

h1.test500 {
    
    font-weight: 500;
    font-size: 21pt;
    line-height: 24pt;
    page-break-inside: avoid;
}

h1.test600 {
    
    font-weight: 600;
    font-size: 21pt;
    line-height: 24pt;
    page-break-inside: avoid;
}

.text-center {
    text-align: center;
}

.cover-test-inteligenta {
    background-color: #FFE1EB;
}

.cover-test-inteligenta .wrapper-cover {
    color: #002D96;
}

.cover-test-inteligenta .wrapper-cover h1,
.cover-test-inteligenta .wrapper-cover h2 {
    color: #002D96;
}

.legend.pink .square {
    background-color: #FF3282;
}

.table-scor-ineligenta {
    table-layout: auto;
    margin: 0 0 4mm;
}

.table-scor-ineligenta td {
    vertical-align: middle !important;
}

.table-scor-ineligenta .icon {
    width: 7mm;
    margin-right: 3mm;
    display: inline-block;
    margin-top: 1.3mm;
}

.table-scor-ineligenta .name {
    margin-top: 1mm;
    
    font-weight: 400;
    font-size: 9pt;
    line-height: 9pt;
    text-transform: uppercase;
    white-space: nowrap;
}

.grafic-scor-inteligenta {
    color: #000;
    width: 80mm;
    position: relative;
}

.grafic-scor-inteligenta .scala {
    
    font-weight: 400;
    font-size: 5pt;
    line-height: 5pt;
    position: absolute;
    left: 0;
    right: 0;
    top: -4mm;
    height: 4mm;
}

.grafic-scor-inteligenta .scala span {
    position: absolute;
    text-align: center;
    top: auto;
}

.grafic-scor-inteligenta .scala span:nth-child(1) {
    left: -1mm;
}

.grafic-scor-inteligenta .scala span:nth-child(2) {
    left: 9.09%;
}

.grafic-scor-inteligenta .scala span:nth-child(3) {
    left: 18.18%;
}

.grafic-scor-inteligenta .scala span:nth-child(4) {
    left: 27.27%;
}

.grafic-scor-inteligenta .scala span:nth-child(5) {
    left: 36.36%;
}

.grafic-scor-inteligenta .scala span:nth-child(6) {
    left: 45.45%;
}

.grafic-scor-inteligenta .scala span:nth-child(7) {
    left: 54.54%;
}

.grafic-scor-inteligenta .scala span:nth-child(8) {
    left: 63.63%;
}

.grafic-scor-inteligenta .scala span:nth-child(9) {
    left: 72.72%;
}

.grafic-scor-inteligenta .scala span:nth-child(10) {
    left: 81.81%;
}

.grafic-scor-inteligenta .scala span:nth-child(11) {
    left: 90.9%;
}

.grafic-scor-inteligenta .scala span:nth-child(12) {
    right: -2.5mm;
}

.grafic-scor-inteligenta .bara {
    background-color: #4A4140;
    -webkit-border-radius: 99999px;
    -moz-border-radius: 99999px;
    border-radius: 99999px;
    height: 10mm;
    width: 80mm;
    display: inline-block;
    position: relative;
    overflow: hidden;
    z-index: 0;
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.grafic-scor-inteligenta .bara .progres {
    height: 100%;
    float: left;
    -webkit-border-radius: 99999px;
    -moz-border-radius: 99999px;
    border-radius: 99999px;
}

.grafic-scor-inteligenta .bara .progres.int-totala {
    background-color: #36BDB1;
}

.grafic-scor-inteligenta .bara .progres.int-verbala {
    background-color: #BCD85F;
}

.grafic-scor-inteligenta .bara .progres.int-numerica {
    background-color: #D95860;
}

.grafic-scor-inteligenta .bara .progres.int-figurala {
    background-color: #7468AD;
}

.grafic-scor-inteligenta .bara .progres.int-fluida {
    background-color: #EFB627;
}

.grafic-scor-inteligenta .bara .progres.int-cristalizata {
    background-color: #2C7DBD;
}

.grafic-scor-inteligenta .bara .progres .valori {
    text-transform: uppercase;
    white-space: nowrap;
    position: absolute;
    left: 2mm;
    margin-top: -0.5mm;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #fff;
}

.grafic-scor-inteligenta .bara .progres .valori .actual {
    
    font-weight: 400;
    font-size: 12pt;
    line-height: 12pt;
    display: inline-block;
    vertical-align: middle;
}

.grafic-scor-inteligenta .bara .progres .valori .ref {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
}

.grafic-scor-inteligenta .bara .progres .valori .ref-val {
    
    font-weight: 400;
    font-size: 6pt;
    line-height: 6pt;
}

.grafic-scor-inteligenta .bara .progres .valori .lab {
    
    font-weight: 400;
    font-size: 3pt;
    line-height: 3pt;
}

.subtitlu-scor-int {
    
    font-weight: 400;
    font-size: 11pt;
    line-height: 11pt;
    text-transform: uppercase;
    margin: 2mm 0 4mm;
}

.table-taget-nota {
    font-size: 0;
    border: 1px solid #000;
    border-bottom: none;
}

.table-taget-nota .dark td {
    background-color: #393231;
    color: #fff;
}

.table-taget-nota td {
    padding: 2mm 5mm;
    
    font-weight: 400;
    font-size: 12pt;
    line-height: 12pt;
}

.table-taget-nota tr td {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

.table-taget-nota tr td:last-child {
    border-right: none;
}

.table-taget-nota .top td {
    border: none;
}

.cover-page {
    margin: -40px;
    padding: 40px;
    position: relative;
}

@media print {
    .cover-page {
        width: 100vw;
        height: 100dvh;
        /*break-after: always;*/
        margin: 0;
        padding: 0;
    }
}

.cover-test-peach .cover-page {
    background-color: #FFE1EB;
}

@media print {
    .cover-test-peach .cover-page {
        background-color: #fff;
    }
}

/*.cover-test-peach .wrapper-cover {*/
/*    color: #002D96;*/
/*}*/

/*.cover-test-peach .wrapper-cover h1,*/
/*.cover-test-peach .wrapper-cover h2 {*/
/*    color: #002D96;*/
/*}*/

/*.cover-test-peach .cover-foo {*/
/*    color: #002D96;*/
/*}*/

@media print {
    .cover-test-peach .cover-foo {
        color: #000;
    }
}

.gauge {
    width: 20mm;
    height: 20mm;
    position: relative;
    z-index: 100;
}

.gauge text {
    font-size: 18px !important;
}

.gauge-circle {
    width: 13mm;
    height: 13mm;
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #000;
}

.gauge-circle .indice {
    font-size: 10pt;
    position: absolute;
    right: -2mm;
    top: -2mm;
}

.gauge-circle .inner {
    background-color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 9mm;
    height: 9mm;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 13pt;
    line-height: 9mm;
    text-align: center;
}

.gauge-circle.extraversie {
    background-color: #642F8E;
}

.gauge-circle.extraversie .indice {
    color: #642F8E;
}

.gauge-circle.amicalitate {
    background-color: #EA6952;
}

.gauge-circle.amicalitate .indice {
    color: #EA6952;
}

.gauge-circle.constinciozitate {
    background-color: #A55263;
}

.gauge-circle.constinciozitate .indice {
    color: #A55263;
}

.gauge-circle.deschidere-mentala {
    background-color: #175C64;
}

.gauge-circle.deschidere-mentala .indice {
    color: #175C64;
}

.gauge-circle.stabilitate-emotionala {
    background-color: #646D2F;
}

.gauge-circle.stabilitate-emotionala .indice {
    color: #646D2F;
}

.gauge-name {
    
    font-weight: 400;
    font-size: 11pt;
    line-height: 13pt;
    margin: 2mm 0;
    text-transform: uppercase;
}

.group-gauge {
    display: inline-block;
}

.group-gauge .icon {
    display: inline-block;
    vertical-align: bottom;
}

.group-gauge .icon img {
    width: 6mm;
    margin-right: 2mm;
}

.group-gauge .gauge-circle {
    display: inline-block;
    vertical-align: bottom;
}

.grup-bara-dubla {
    position: relative;
}

.grup-bara-dubla:after {
    width: 1mm;
    height: 8mm;
    border-right: 0.7pt dashed #000;
    display: block;
    content: '';
    position: absolute;
    right: 46.2mm;
    top: 6.5mm;
    z-index: 0;
}

.grup-scala-sec.dinamism .label-scala-sec:after,
.grup-scala-sec.dinamism .bara-scor-personalitate .progres {
    background-color: #9370B1;
}

.grup-scala-sec.dominanta .label-scala-sec:after,
.grup-scala-sec.dominanta .bara-scor-personalitate .progres {
    background-color: #BCA6D0;
}

.grup-scala-sec.cordialitate .label-scala-sec:after,
.grup-scala-sec.cordialitate .bara-scor-personalitate .progres {
    background-color: #F7995F;
}

.grup-scala-sec.cooperare .label-scala-sec:after,
.grup-scala-sec.cooperare .bara-scor-personalitate .progres {
    background-color: #FEC987;
}

.grup-scala-sec.meticulozitate .label-scala-sec:after,
.grup-scala-sec.meticulozitate .bara-scor-personalitate .progres {
    background-color: #F38397;
}

.grup-scala-sec.perseverenta .label-scala-sec:after,
.grup-scala-sec.perseverenta .bara-scor-personalitate .progres {
    background-color: #EFBCCD;
}

.grup-scala-sec.deschidere-cultura .label-scala-sec:after,
.grup-scala-sec.deschidere-cultura .bara-scor-personalitate .progres {
    background-color: #2E8E90;
}

.grup-scala-sec.deschidere-experiente .label-scala-sec:after,
.grup-scala-sec.deschidere-experiente .bara-scor-personalitate .progres {
    background-color: #94CECF;
}

.grup-scala-sec.controlul-impulsurilor .label-scala-sec:after,
.grup-scala-sec.controlul-impulsurilor .bara-scor-personalitate .progres {
    background-color: #8C9F40;
}

.grup-scala-sec.controlul-emotiilor .label-scala-sec:after,
.grup-scala-sec.controlul-emotiilor .bara-scor-personalitate .progres {
    background-color: #BED963;
}

.label-scala-sec {
    position: relative;
    font: 6pt 'NotoSans';
    font-weight: 300;
    line-height: 7pt;
    padding-right: 7mm;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4mm;
    margin-top: 1mm;
    z-index: 1;
}

.label-scala-sec:after {
    display: block;
    content: '';
    width: 3mm;
    height: 3mm;
    background-color: #C0C2C7;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.bara-scor-personalitate {
    color: #000;
    width: 40mm;
    position: relative;
    margin-top: 2mm;
    display: inline-block;
    vertical-align: middle;
}

.bara-scor-personalitate .bara {
    background-color: #C0C2C7;
    -webkit-border-radius: 99999px;
    -moz-border-radius: 99999px;
    border-radius: 99999px;
    height: 6mm;
    width: 40mm;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-collapse: collapse;
    z-index: 0;
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}

.bara-scor-personalitate .bara .progres {
    overflow: hidden;
    -webkit-border-radius: 99999px;
    -moz-border-radius: 99999px;
    border-radius: 99999px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.bara-scor-personalitate .bara .progres .valori {
    text-transform: uppercase;
    white-space: nowrap;
    position: absolute;
    left: 2mm;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #000;
    
    font-weight: 400;
    font-size: 11pt;
    line-height: 11pt;
}

h3.name-test-personalitate {
    margin: 0 3mm 1.7mm 0;
    white-space: nowrap;
}

.marked.extraversie:before {
    border-color: #642F8E;
}

.marked.extraversie .legend .square {
    background-color: #642F8E;
}

.marked.amicalitate:before {
    border-color: #EA6952;
}

.marked.amicalitate .legend .square {
    background-color: #EA6952;
}

.marked.constinciozitate:before {
    border-color: #A55263;
}

.marked.constinciozitate .legend .square {
    background-color: #A55263;
}

.marked.deschidere-mentala:before {
    border-color: #175C64;
}

.marked.deschidere-mentala .legend .square {
    background-color: #175C64;
}

.marked.stabilitate-emotionala:before {
    border-color: #646D2F;
}

.marked.stabilitate-emotionala .legend .square {
    background-color: #646D2F;
}

.marked.dinamism:before {
    border-color: #9370B1;
}

.marked.dinamism .legend .square {
    background-color: #9370B1;
}

.marked.dominanta:before {
    border-color: #BCA6D0;
}

.marked.dominanta .legend .square {
    background-color: #BCA6D0;
}

.marked.cordialitate:before {
    border-color: #F7995F;
}

.marked.cordialitate .legend .square {
    background-color: #F7995F;
}

.marked.cooperare:before {
    border-color: #FEC987;
}

.marked.cooperare .legend .square {
    background-color: #FEC987;
}

.marked.constinciozitate:before {
    border-color: #A55263;
}

.marked.constinciozitate .legend .square {
    background-color: #A55263;
}

.marked.meticulozitate:before {
    border-color: #F38397;
}

.marked.meticulozitate .legend .square {
    background-color: #F38397;
}

.marked.perseverenta:before {
    border-color: #EFBCCD;
}

.marked.perseverenta .legend .square {
    background-color: #EFBCCD;
}

.marked.deschidere-cultura:before {
    border-color: #2E8E90;
}

.marked.deschidere-cultura .legend .square {
    background-color: #2E8E90;
}

.marked.deschidere-experiente:before {
    border-color: #94CECF;
}

.marked.deschidere-experiente .legend .square {
    background-color: #94CECF;
}

.marked.controlul-impulsurilor:before {
    border-color: #8C9F40;
}

.marked.controlul-impulsurilor .legend .square {
    background-color: #8C9F40;
}

.marked.controlul-emotiilor:before {
    border-color: #BED963;
}

.marked.controlul-emotiilor .legend .square {
    background-color: #BED963;
}

.square-value-personalitate {
    padding: 2mm;
    
    font-size: 15pt;
    font-weight: 500;
    line-height: 15pt;
    display: inline-block;
    color: #fff;
}

.square-value-personalitate.dinamism {
    background-color: #9370B1;
}

.square-value-personalitate.dominanta {
    background-color: #BCA6D0;
}

.square-value-personalitate.cordialitate {
    background-color: #F7995F;
}

.square-value-personalitate.cooperare {
    background-color: #FEC987;
}

.square-value-personalitate.meticulozitate {
    background-color: #F38397;
}

.square-value-personalitate.perseverenta {
    background-color: #EFBCCD;
}

.square-value-personalitate.deschidere-cultura {
    background-color: #2E8E90;
}

.square-value-personalitate.deschidere-experiente {
    background-color: #94CECF;
}

.square-value-personalitate.controlul-impulsurilor {
    background-color: #8C9F40;
}

.square-value-personalitate.controlul-emotiilor {
    background-color: #BED963;
}

.bg-grey-light {
    background-color: #F9F9F9;
}

.subsection-icon-check {
    position: absolute;
    top: 50%;
    left: 3mm;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.subsection-check {
    background-color: #F9F9F9;
    padding-top: 3mm;
    padding-bottom: 3mm;
    position: relative;
}

.cover-test-stiluri-invatare {
    background-color: #E8D1FF;
}

.cover-test-stiluri-invatare .wrapper-cover {
    color: #002D96;
}

.cover-test-stiluri-invatare .wrapper-cover h1,
.cover-test-stiluri-invatare .wrapper-cover h2 {
    color: #002D96;
}

.icon-subsection-stil-invatare {
    position: absolute;
    top: 0;
    left: 0;
}

.subsection-content-stil-invatare {
    position: relative;
    padding-left: 40pt;
}

.subsection-content-stil-invatare .icon-subsection-stil-invatare {
    position: absolute;
    top: 0;
    left: 3mm;
    padding: 2pt;
}

.subsection-content-stil-invatare .icon-subsection-stil-invatare img {
    width: 8mm;
    display: block;
}

.subsection-content-stil-invatare .icon-subsection-stil-invatare.extravert-introvert {
    background-color: #d1d2d4;
}

.subsection-content-stil-invatare .icon-subsection-stil-invatare.extravert-introvert.active {
    background-color: #FBB042;
}

.subsection-content-stil-invatare .icon-subsection-stil-invatare.practic-imaginativ {
    background-color: #d1d2d4;
}

.subsection-content-stil-invatare .icon-subsection-stil-invatare.practic-imaginativ.active {
    background-color: #EC1C24;
}

.subsection-content-stil-invatare .icon-subsection-stil-invatare.rational-emotional {
    background-color: #d1d2d4;
}

.subsection-content-stil-invatare .icon-subsection-stil-invatare.rational-emotional.active {
    color: #fff;
    background-color: #662D90;
}

.subsection-content-stil-invatare .icon-subsection-stil-invatare.organizat-flexibil {
    background-color: #d1d2d4;
}

.subsection-content-stil-invatare .icon-subsection-stil-invatare.organizat-flexibil.active {
    background-color: #8CC53F;
}

.subsection-content-stil-invatare .icon-multiple-styles {
    position: absolute;
    top: -15mm;
    right: 0;
    width: 20mm;
}

.subsection-content-stil-invatare h3 {
    
    font-weight: 500;
    font-size: 16pt;
    line-height: 16pt;
    margin: 0 0 6mm;
    padding-top: 2mm;
    text-transform: uppercase;
    page-break-inside: avoid;
    page-break-after: avoid;
}

.grafic-scor-stiluri-invatare {
    position: relative;
    padding-top: 13mm;
    margin-bottom: 10mm;
}

.grafic-scor-stiluri-invatare .ruller {
    position: absolute;
    left: -1mm;
    right: -1mm;
    top: 0;
}

.grafic-scor-stiluri-invatare .ruller img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto !important;
}

.grafic-scor-stiluri-invatare .bara {
    height: 8mm;
    width: 100%;
    background-color: #D1D2D4;
    position: relative;
    margin-bottom: 2mm;
}

.grafic-scor-stiluri-invatare .bara .progres {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
}

.grafic-scor-stiluri-invatare .bara .progres.extravert {
    background-color: #FBB042;
}

.grafic-scor-stiluri-invatare .bara .progres.imaginativ {
    background-color: #EC1C24;
}

.grafic-scor-stiluri-invatare .bara .progres.emotional {
    background-color: #662D90;
}

.grafic-scor-stiluri-invatare .bara .progres.flexibil {
    background-color: #8CC53F;
}

.grafic-scor-stiluri-invatare .bara .left {
    position: relative;
    z-index: 1;
    float: left;
    padding-left: 0.5mm;
}

.grafic-scor-stiluri-invatare .bara .left img {
    display: inline-block;
    vertical-align: middle;
    line-height: 8mm;
    height: 8mm;
    width: auto !important;
}

.grafic-scor-stiluri-invatare .bara .left .lab {
    display: inline-block;
    vertical-align: middle;
    
    font-size: 18pt;
    font-weight: 400;
    line-height: 8mm;
    position: absolute;
    left: -2mm;
    top: 50%;
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    -o-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
}

.grafic-scor-stiluri-invatare .bara .left .value {
    display: inline-block;
    vertical-align: middle;
    
    font-size: 12pt;
    font-weight: 400;
    line-height: 8mm;
    padding-left: 1mm;
}

.grafic-scor-stiluri-invatare .bara .right {
    float: right;
    padding-right: 0.5mm;
}

.grafic-scor-stiluri-invatare .bara .right img {
    line-height: 8mm;
    height: 8mm;
    width: auto !important;
}

.grafic-scor-stiluri-invatare .bara .right .lab {
    display: inline-block;
    vertical-align: middle;
    
    font-size: 18pt;
    font-weight: 400;
    line-height: 8mm;
    position: absolute;
    right: -2mm;
    top: 50%;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%);
}

.subsection.extravert-introvert .icon-subsection-stil-invatare {
    background-color: #d1d2d4;
}

.subsection.extravert-introvert.active {
    background-color: #FBB042;
    padding: 5pt;
}

.subsection.extravert-introvert.active .icon-subsection-stil-invatare {
    background-color: #FBB042;
}

.subsection.practic-imaginativ .icon-subsection-stil-invatare {
    background-color: #d1d2d4;
}

.subsection.practic-imaginativ.active {
    background-color: #EC1C24;
    padding: 5pt;
}

.subsection.practic-imaginativ.active .icon-subsection-stil-invatare {
    background-color: #EC1C24;
}

.subsection.rational-emotional .icon-subsection-stil-invatare {
    background-color: #d1d2d4;
}

.subsection.rational-emotional.active {
    color: #fff;
    background-color: #662D90;
    padding: 5pt;
}

.subsection.rational-emotional.active .icon-subsection-stil-invatare {
    background-color: #662D90;
}

.subsection.organizat-flexibil .icon-subsection-stil-invatare {
    background-color: #d1d2d4;
}

.subsection.organizat-flexibil.active {
    background-color: #8CC53F;
    padding: 5pt;
}

.subsection.organizat-flexibil.active .icon-subsection-stil-invatare {
    background-color: #8CC53F;
}

.box-icons-multiple-styles {
    position: absolute;
    top: -15mm;
    right: 0;
    width: 20mm;
}

.box-icons-multiple-styles img {
    width: 100% !important;
    display: block;
    height: auto !important;
}

.box-icons-multiple-styles .extravert-introvert {
    background-color: #FBB042;
    padding: 3pt;
    float: left;
    width: 44%;
    margin: 3%;
}

.box-icons-multiple-styles .practic-imaginativ {
    background-color: #EC1C24;
    padding: 3pt;
    float: left;
    width: 44%;
    margin: 3%;
}

.box-icons-multiple-styles .rational-emotional {
    background-color: #662D90;
    padding: 3pt;
    float: left;
    width: 44%;
    margin: 3%;
}

.box-icons-multiple-styles .organizat-flexibil {
    background-color: #8CC53F;
    padding: 3pt;
    float: left;
    width: 44%;
    margin: 3%;
}

.hex-tip {
    display: inline-block;
    margin-bottom: 1mm;
}

.hex-tip .inner {
    color: #000;
    text-align: center;
    
    width: 8mm;
    height: 12mm;
    position: relative;
    padding: 2mm 0;
}

.hex-tip .inner:before {
    border-right: 3mm solid #EB2130;
    border-top: 6mm solid transparent;
    border-bottom: 6mm solid transparent;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(-100%, 0);
    -moz-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    -o-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
}

.hex-tip .inner:after {
    float: left;
    border-left: 3mm solid #EB2130;
    border-top: 6mm solid transparent;
    border-bottom: 6mm solid transparent;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(100%, 0);
    -moz-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    -o-transform: translate(100%, 0);
    transform: translate(100%, 0);
}

.hex-tip .tip {
    font-size: 15pt;
    font-weight: 700;
}

.hex-tip .scor {
    font-size: 9pt;
    font-weight: 400;
}

.hex-tip.tip-R .inner {
    background-color: #EB2130;
}

.hex-tip.tip-R .inner:after {
    border-left-color: #EB2130;
}

.hex-tip.tip-R .inner:before {
    border-right-color: #EB2130;
}

.hex-tip.tip-I .inner {
    background-color: #00ADEE;
}

.hex-tip.tip-I .inner:after {
    border-left-color: #00ADEE;
}

.hex-tip.tip-I .inner:before {
    border-right-color: #00ADEE;
}

.hex-tip.tip-A .inner {
    background-color: #FED11F;
}

.hex-tip.tip-A .inner:after {
    border-left-color: #FED11F;
}

.hex-tip.tip-A .inner:before {
    border-right-color: #FED11F;
}

.hex-tip.tip-S .inner {
    background-color: #A6CE3A;
}

.hex-tip.tip-S .inner:after {
    border-left-color: #A6CE3A;
}

.hex-tip.tip-S .inner:before {
    border-right-color: #A6CE3A;
}

.hex-tip.tip-E .inner {
    background-color: #EA238E;
}

.hex-tip.tip-E .inner:after {
    border-left-color: #EA238E;
}

.hex-tip.tip-E .inner:before {
    border-right-color: #EA238E;
}

.hex-tip.tip-C .inner {
    background-color: #86529F;
}

.hex-tip.tip-C .inner:after {
    border-left-color: #86529F;
}

.hex-tip.tip-C .inner:before {
    border-right-color: #86529F;
}

.table-scor-riasec {
    table-layout: fixed;
}

.table-scor-riasec td {
    text-align: center;
}

.hex-tip-small {
    display: inline-block;
    margin-bottom: 1mm;
    position: relative;
}

.hex-tip-small .inner {
    color: #000;
    text-align: center;
    
    width: 3mm;
    height: 4mm;
    position: relative;
    padding: 0;
}

.hex-tip-small .inner:before {
    border-right: 1mm solid #EB2130;
    border-top: 2mm solid transparent;
    border-bottom: 2mm solid transparent;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(-100%, 0);
    -moz-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    -o-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
}

.hex-tip-small .inner:after {
    float: left;
    border-left: 1mm solid #EB2130;
    border-top: 2mm solid transparent;
    border-bottom: 2mm solid transparent;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(100%, 0);
    -moz-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    -o-transform: translate(100%, 0);
    transform: translate(100%, 0);
}

.hex-tip-small .tip {
    font-size: 5pt;
    font-weight: 500;
    line-height: 4mm;
}

.hex-tip-small .scor {
    font-size: 4pt;
    font-weight: 500;
    position: absolute;
    right: -1mm;
    top: 0;
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    -ms-transform: translate(100%, -50%);
    -o-transform: translate(100%, -50%);
    transform: translate(100%, -50%);
}

.hex-tip-small.tip-R .inner {
    background-color: #EB2130;
}

.hex-tip-small.tip-R .inner:after {
    border-left-color: #EB2130;
}

.hex-tip-small.tip-R .inner:before {
    border-right-color: #EB2130;
}

.hex-tip-small.tip-I .inner {
    background-color: #00ADEE;
}

.hex-tip-small.tip-I .inner:after {
    border-left-color: #00ADEE;
}

.hex-tip-small.tip-I .inner:before {
    border-right-color: #00ADEE;
}

.hex-tip-small.tip-A .inner {
    background-color: #FED11F;
}

.hex-tip-small.tip-A .inner:after {
    border-left-color: #FED11F;
}

.hex-tip-small.tip-A .inner:before {
    border-right-color: #FED11F;
}

.hex-tip-small.tip-S .inner {
    background-color: #A6CE3A;
}

.hex-tip-small.tip-S .inner:after {
    border-left-color: #A6CE3A;
}

.hex-tip-small.tip-S .inner:before {
    border-right-color: #A6CE3A;
}

.hex-tip-small.tip-E .inner {
    background-color: #EA238E;
}

.hex-tip-small.tip-E .inner:after {
    border-left-color: #EA238E;
}

.hex-tip-small.tip-E .inner:before {
    border-right-color: #EA238E;
}

.hex-tip-small.tip-C .inner {
    background-color: #86529F;
}

.hex-tip-small.tip-C .inner:after {
    border-left-color: #86529F;
}

.hex-tip-small.tip-C .inner:before {
    border-right-color: #86529F;
}

.table-tip-activitati .name {
    
    font-size: 6pt;
    font-weight: 700;
    line-height: 6pt;
}

.table-tip-activitati td {
    padding: 2mm 1mm;
}

.table-tip-activitati td .text {
    
    font-weight: 400;
    font-size: 4pt;
    line-height: 5pt;
}

.table-tip-activitati td.first {
    background-color: #414042;
    color: #fff;
}

.table-tip-activitati td.realist {
    background-color: #BDBEBF;
}

.table-tip-activitati td.investigativ {
    background-color: #27AAE1;
}

.table-tip-activitati td.artistic {
    background-color: #FED11F;
}

.table-tip-activitati td.social {
    background-color: #BDBEBF;
}

.table-tip-activitati td.intreprinzator {
    background-color: #EA238E;
}

.table-tip-activitati td.conventional {
    background-color: #BDBEBF;
}

.table-tip-activitati thead td {
    padding: 0 1mm;
}

.nota {
    font-size: 6pt !important;
    line-height: 7pt;
}

.grup-bars-riasic {
    position: relative;
    width: 100%;
}

.grup-bars-riasic .scala {
    
    font-weight: 400;
    font-size: 6pt;
    line-height: 6pt;
    position: absolute;
    left: 11mm;
    right: 0;
    top: -4mm;
}

.grup-bars-riasic .scala span {
    position: absolute;
    text-align: center;
    top: auto;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.grup-bars-riasic .scala span:nth-child(1) {
    left: 0;
}

.grup-bars-riasic .scala span:nth-child(2) {
    left: 10%;
}

.grup-bars-riasic .scala span:nth-child(3) {
    left: 20%;
}

.grup-bars-riasic .scala span:nth-child(4) {
    left: 30%;
}

.grup-bars-riasic .scala span:nth-child(5) {
    left: 40%;
}

.grup-bars-riasic .scala span:nth-child(6) {
    left: 50%;
}

.grup-bars-riasic .scala span:nth-child(7) {
    left: 60%;
}

.grup-bars-riasic .scala span:nth-child(8) {
    left: 70%;
}

.grup-bars-riasic .scala span:nth-child(9) {
    left: 80%;
}

.grup-bars-riasic .scala span:nth-child(10) {
    left: 90%;
}

.grup-bars-riasic .scala span:nth-child(11) {
    right: -3.5mm;
}

.grup-bars-riasic .scala span:after {
    display: block;
    content: '';
    border-left: 0.5pt dashed #000;
    left: 0;
    top: 4mm;
    -webkit-transform: translate(50%, 0);
    -moz-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    transform: translate(50%, 0);
    height: 30mm;
}

.grup-bars-riasic .scala-val {
    
    font-weight: 400;
    font-size: 6pt;
    line-height: 6pt;
    position: absolute;
    left: 11mm;
    right: 0;
    top: -4mm;
}

.grup-bars-riasic .scala-val span {
    position: absolute;
    text-align: center;
    top: auto;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.grup-bars-riasic .scala-val span:nth-child(1) {
    left: 0;
}

.grup-bars-riasic .scala-val span:nth-child(2) {
    left: 10%;
}

.grup-bars-riasic .scala-val span:nth-child(3) {
    left: 20%;
}

.grup-bars-riasic .scala-val span:nth-child(4) {
    left: 30%;
}

.grup-bars-riasic .scala-val span:nth-child(5) {
    left: 40%;
}

.grup-bars-riasic .scala-val span:nth-child(6) {
    left: 50%;
}

.grup-bars-riasic .scala-val span:nth-child(7) {
    left: 60%;
}

.grup-bars-riasic .scala-val span:nth-child(8) {
    left: 70%;
}

.grup-bars-riasic .scala-val span:nth-child(9) {
    left: 80%;
}

.grup-bars-riasic .scala-val span:nth-child(10) {
    left: 90%;
}

.grup-bars-riasic .scala-val span:nth-child(11) {
    right: -3.5mm;
}

.grup-bars-riasic .scala-line {
    position: absolute;
    left: 11mm;
    right: 0;
    top: 0;
    bottom: 0;
}

.grup-bars-riasic .scala-line span {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.grup-bars-riasic .scala-line span:nth-child(1) {
    left: 0;
}

.grup-bars-riasic .scala-line span:nth-child(2) {
    left: 10%;
}

.grup-bars-riasic .scala-line span:nth-child(3) {
    left: 20%;
}

.grup-bars-riasic .scala-line span:nth-child(4) {
    left: 30%;
}

.grup-bars-riasic .scala-line span:nth-child(5) {
    left: 40%;
}

.grup-bars-riasic .scala-line span:nth-child(6) {
    left: 50%;
}

.grup-bars-riasic .scala-line span:nth-child(7) {
    left: 60%;
}

.grup-bars-riasic .scala-line span:nth-child(8) {
    left: 70%;
}

.grup-bars-riasic .scala-line span:nth-child(9) {
    left: 80%;
}

.grup-bars-riasic .scala-line span:nth-child(10) {
    left: 90%;
}

.grup-bars-riasic .scala-line span:nth-child(11) {
    right: 0;
}

.grup-bars-riasic .scala-line span:after {
    display: block;
    content: '';
    border-left: 0.6pt dashed #000;
    left: 0;
    top: 0;
    -webkit-transform: translate(50%, 0);
    -moz-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    transform: translate(50%, 0);
    height: 100%;
}

.grup-bars-riasic .table-bars {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
}

.grup-bars-riasic .riasic-bar {
    height: 5mm;
    display: table-row;
}

.grup-bars-riasic .icon {
    display: table-cell;
    background-color: #414042;
    width: 5mm;
    height: 5mm;
    text-align: center;
    padding: 0;
    font-size: 0.1pt;
}

.grup-bars-riasic .icon img {
    width: 5mm;
    display: inline-block;
    margin: 0;
}

.grup-bars-riasic .scor {
    height: 5mm;
    width: 6mm;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    font-size: 6pt;
}

.grup-bars-riasic .bar {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    height: 5mm;
    width: 100%;
}

.grup-bars-riasic .bar .progres {
    position: absolute;
    height: 5mm;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 0 1mm;
    
    font-size: 9pt;
    font-weight: 700;
    line-height: 5mm;
}

.grup-bars-riasic .bar .progres.realist {
    background-color: #EB2130;
}

.grup-bars-riasic .bar .progres.investigativ {
    background-color: #27AAE1;
}

.grup-bars-riasic .bar .progres.artistic {
    background-color: #FED11F;
}

.grup-bars-riasic .bar .progres.social {
    background-color: #A6CE3A;
}

.grup-bars-riasic .bar .progres.intreprinzator {
    background-color: #EA238E;
}

.grup-bars-riasic .bar .progres.conventional {
    background-color: #86529F;
}

.cod-holland {
    background-color: #414042;
    color: #fff;
    text-align: center;
    width: 100%;
    padding: 5mm 0 6mm;
    margin-bottom: 2mm;
}

.cod-holland .title {
    
    font-size: 12pt;
    font-weight: 700;
    margin-bottom: 2mm;
}

.cod-holland .hex-tip {
    margin-bottom: 0;
}

.cod-holland .scor-holland-wrapper {
    width: 60%;
    margin: 0 auto;
    padding: 1mm 0;
}

.cod-holland .scor-holland-wrapper .white {
    position: relative;
    background-color: #fff;
}

.cod-holland .scor-holland-wrapper .white:before {
    border-right: 3mm solid #fff;
    border-top: 6.5mm solid transparent;
    border-bottom: 6.5mm solid transparent;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(-100%, 0);
    -moz-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    -o-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
}

.cod-holland .scor-holland-wrapper .white:after {
    float: left;
    border-left: 3mm solid #fff;
    border-top: 6.5mm solid transparent;
    border-bottom: 6.5mm solid transparent;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(100%, 0);
    -moz-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    -o-transform: translate(100%, 0);
    transform: translate(100%, 0);
}

.coduri-secundare-holland {
    background-color: #F2F3F2;
    padding: 1mm 7mm;
}

.table-coduri-secundare-holland .cod-number {
    
    font-size: 23pt;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle;
    color: #414042;
    margin-right: 5mm;
    width: 10mm;
    text-align: center;
}

.table-coduri-secundare-holland .title {
    
    font-size: 9pt;
    font-weight: 700;
    display: inline-block;
}

.table-coduri-secundare-holland .table-scor-riasec {
    width: 32mm;
    background-color: #414042;
    position: relative;
}

.table-coduri-secundare-holland .table-scor-riasec td {
    height: 7mm;
    vertical-align: middle;
}

.table-coduri-secundare-holland .table-scor-riasec:before {
    border-right: 2.5mm solid #414042;
    border-top: 5.4mm solid transparent;
    border-bottom: 5.4mm solid transparent;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(-100%, 0);
    -moz-transform: translate(-100%, 0);
    -ms-transform: translate(-100%, 0);
    -o-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
}

.table-coduri-secundare-holland .table-scor-riasec:after {
    float: left;
    border-left: 2.5mm solid #414042;
    border-top: 5.4mm solid transparent;
    border-bottom: 5.4mm solid transparent;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(100%, 0);
    -moz-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    -o-transform: translate(100%, 0);
    transform: translate(100%, 0);
}

.table-coduri-secundare-holland .hex-tip {
    margin: 0;
}

.table-coduri-secundare-holland .hex-tip .inner {
    width: 6mm;
    height: 8mm;
    padding: 2mm 0;
}

.table-coduri-secundare-holland .hex-tip .inner:before {
    border-right-width: 2mm;
    border-top: 4mm solid transparent;
    border-bottom: 4mm solid transparent;
}

.table-coduri-secundare-holland .hex-tip .inner:after {
    float: left;
    border-left-width: 2mm;
    border-top: 4mm solid transparent;
    border-bottom: 4mm solid transparent;
}

.table-coduri-secundare-holland .table-inline {
    display: inline-block;
    vertical-align: middle;
}

.subsection-left-number {
    
    font-size: 45pt;
    font-weight: 700;
    line-height: 100%;
    position: absolute;
    top: 0;
    left: 3mm;
    text-align: right;
}

.box-true {
    
    font-size: 11pt;
    font-weight: 700;
    background-color: #BDBEBF;
    padding: 1mm 2mm;
    margin: 1mm 5mm 1mm 0;
    text-align: center;
}

.list-scor-details {
    position: relative;
    width: 100%;
    margin-bottom: 3pt;
}

.list-scor-details .scala-val {
    
    font-weight: 400;
    font-size: 6pt;
    line-height: 6pt;
    position: absolute;
    left: 109pt;
    right: 0;
    top: -4mm;
}

.list-scor-details .scala-val span {
    position: absolute;
    text-align: center;
    top: auto;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.list-scor-details .scala-val span:nth-child(1) {
    left: 0;
}

.list-scor-details .scala-val span:nth-child(2) {
    left: 10%;
}

.list-scor-details .scala-val span:nth-child(3) {
    left: 20%;
}

.list-scor-details .scala-val span:nth-child(4) {
    left: 30%;
}

.list-scor-details .scala-val span:nth-child(5) {
    left: 40%;
}

.list-scor-details .scala-val span:nth-child(6) {
    left: 50%;
}

.list-scor-details .scala-val span:nth-child(7) {
    left: 60%;
}

.list-scor-details .scala-val span:nth-child(8) {
    left: 70%;
}

.list-scor-details .scala-val span:nth-child(9) {
    left: 80%;
}

.list-scor-details .scala-val span:nth-child(10) {
    left: 90%;
}

.list-scor-details .scala-val span:nth-child(11) {
    right: -3.5mm;
}

.list-scor-details .scala-line {
    position: absolute;
    left: 109pt;
    right: 0;
    top: 0;
    bottom: 0;
}

.list-scor-details .scala-line span {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.list-scor-details .scala-line span:nth-child(1) {
    left: 0;
}

.list-scor-details .scala-line span:nth-child(2) {
    left: 10%;
}

.list-scor-details .scala-line span:nth-child(3) {
    left: 20%;
}

.list-scor-details .scala-line span:nth-child(4) {
    left: 30%;
}

.list-scor-details .scala-line span:nth-child(5) {
    left: 40%;
}

.list-scor-details .scala-line span:nth-child(6) {
    left: 50%;
}

.list-scor-details .scala-line span:nth-child(7) {
    left: 60%;
}

.list-scor-details .scala-line span:nth-child(8) {
    left: 70%;
}

.list-scor-details .scala-line span:nth-child(9) {
    left: 80%;
}

.list-scor-details .scala-line span:nth-child(10) {
    left: 90%;
}

.list-scor-details .scala-line span:nth-child(11) {
    right: 0;
}

.list-scor-details .scala-line span:after {
    display: block;
    content: '';
    border-left: 0.6pt dashed #000;
    left: 0;
    top: 0;
    -webkit-transform: translate(50%, 0);
    -moz-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    transform: translate(50%, 0);
    height: 100%;
}

.list-scor-details .wrapper-list-scor-table {
    background-color: #F2F3F2;
    padding: 2pt 0 2pt 10pt;
}

.list-scor-details .table-bars {
    display: table;
    border-collapse: collapse;
    width: 100%;
}

.list-scor-details .riasic-bar {
    height: 3mm;
    display: table-row;
}

.list-scor-details .bar-label {
    width: 100pt;
    font-size: 7pt;
    margin-bottom: 2pt;
}

.list-scor-details .bar {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    height: 3mm;
    width: 100%;
}

.list-scor-details .bar .progres {
    position: absolute;
    height: 3mm;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 0 1mm;
    
    font-size: 7pt;
    font-weight: 700;
    line-height: 3mm;
}

.list-scor-details .bar .progres.realist {
    background-color: #EB2130;
}

.list-scor-details .bar .progres.investigativ {
    background-color: #27AAE1;
}

.list-scor-details .bar .progres.artistic {
    background-color: #FED11F;
}

.list-scor-details .bar .progres.social {
    background-color: #A6CE3A;
}

.list-scor-details .bar .progres.intreprinzator {
    background-color: #EA238E;
}

.list-scor-details .bar .progres.conventional {
    background-color: #86529F;
}

.listare-scor-special-copy {
    font-size: 7pt;
    margin-top: 5mm;
}

.cover-raport5 {
    background-color: #FFE1EB;
}

.cover-raport5 .wrapper-cover {
    color: #002D96;
}

/*.cover-raport5 .wrapper-cover h1,*/
/*.cover-raport5 .wrapper-cover h2,*/
/*.cover-raport5 .wrapper-cover h4 {*/
/*    color: #002D96;*/
/*}*/

.cover-raport5 .wrapper-cover h4 {
    font-weight: 400;
}

.cover-raport5 .cover-img {
    width: 140mm !important;
    height: auto !important;
    left: auto;
    right: 0;
}

.color-r5 {
    color: #E17B9D;
}

.mb-6 {
    margin-bottom: 6mm !important;
}

.mb-1 {
    margin-bottom: 1mm !important;
}

.mb-0 {
    margin-bottom: 1mm !important;
}

.mb-3 {
    margin-bottom: 3mm !important;
}

.mb-13 {
    margin-bottom: 13.5mm !important;
}

.grafic-baron {
    position: relative;
    width: 100%;
    margin-bottom: 50pt;
}

.grafic-baron .scala-val {
    
    font-weight: 400;
    font-size: 6pt;
    line-height: 6pt;
    position: absolute;
    left: 109pt;
    right: 0;
    top: -4mm;
}

.grafic-baron .scala-val.bottom {
    top: auto;
    bottom: -2mm;
}

.grafic-baron .scala-val span {
    position: absolute;
    text-align: center;
    top: auto;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.grafic-baron .scala-val span:nth-child(1) {
    left: 0;
}

.grafic-baron .scala-val span:nth-child(2) {
    left: 10%;
}

.grafic-baron .scala-val span:nth-child(3) {
    left: 20%;
}

.grafic-baron .scala-val span:nth-child(4) {
    left: 30%;
}

.grafic-baron .scala-val span:nth-child(5) {
    left: 40%;
}

.grafic-baron .scala-val span:nth-child(6) {
    left: 50%;
}

.grafic-baron .scala-val span:nth-child(7) {
    left: 60%;
}

.grafic-baron .scala-val span:nth-child(8) {
    left: 70%;
}

.grafic-baron .scala-val span:nth-child(9) {
    left: 80%;
}

.grafic-baron .scala-val span:nth-child(10) {
    left: 90%;
}

.grafic-baron .scala-val span:nth-child(11) {
    right: -3.5mm;
}

.grafic-baron .scala-line {
    position: absolute;
    left: 109pt;
    right: 0;
    top: 0;
    bottom: 0;
}

.grafic-baron .scala-line span {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.grafic-baron .scala-line span:nth-child(1) {
    left: 0;
}

.grafic-baron .scala-line span:nth-child(2) {
    left: 10%;
}

.grafic-baron .scala-line span:nth-child(3) {
    left: 20%;
}

.grafic-baron .scala-line span:nth-child(4) {
    left: 30%;
}

.grafic-baron .scala-line span:nth-child(5) {
    left: 40%;
}

.grafic-baron .scala-line span:nth-child(6) {
    left: 50%;
}

.grafic-baron .scala-line span:nth-child(7) {
    left: 60%;
}

.grafic-baron .scala-line span:nth-child(8) {
    left: 70%;
}

.grafic-baron .scala-line span:nth-child(9) {
    left: 80%;
}

.grafic-baron .scala-line span:nth-child(10) {
    left: 90%;
}

.grafic-baron .scala-line span:nth-child(11) {
    right: 0;
}

.grafic-baron .scala-line span:after {
    display: block;
    content: '';
    border-left: 0.6pt dashed #000;
    left: 0;
    top: 0;
    -webkit-transform: translate(50%, 0);
    -moz-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    transform: translate(50%, 0);
    height: 100%;
}

.grafic-baron .scala-line .color-zone {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 40%;
    width: 20%;
    background-color: rgba(233, 151, 159, 0.2);
}

.grafic-baron .scala-line .grey-zone {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 40%;
    width: 20%;
    background-color: rgba(0, 0, 0, 0.04);
}

.grafic-baron .wrapper-grafice-baron {
    background-color: #F2F3F2;
    padding: 10pt 0 10pt 10pt;
}

.grafic-baron .table-bars {
    display: table;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    margin: 10pt 0 20pt;
}

.grafic-baron .table-bars:last-child {
    margin-bottom: 10pt;
}

.grafic-baron .grafic-baron-bar {
    display: table-row;
}

.grafic-baron .grafic-baron-bottom-range {
    position: absolute;
    bottom: -15pt;
    left: 109pt;
    right: 0;
}

.grafic-baron .grafic-baron-bottom-range .range {
    position: absolute;
    text-align: center;
    top: 0;
}

.grafic-baron .grafic-baron-bottom-range .range .border {
    height: 2mm;
    width: 100%;
    border-bottom: 1.1pt solid #707070;
    margin-bottom: 4pt;
    position: relative;
}

.grafic-baron .grafic-baron-bottom-range .range .border:before {
    position: absolute;
    left: 0;
    height: 2mm;
    width: 1.1pt;
    bottom: 0;
    background-color: #707070;
    content: '';
}

.grafic-baron .grafic-baron-bottom-range .range .border:after {
    position: absolute;
    right: 0;
    height: 2mm;
    width: 1.1pt;
    bottom: 0;
    background-color: #707070;
    content: '';
}

.grafic-baron .grafic-baron-bottom-range .range .name {
    
    font-size: 5pt;
    font-weight: 400;
    line-height: 110%;
    margin-bottom: 2pt;
    white-space: nowrap;
    text-align: center;
    color: #414042;
}

.grafic-baron .bar-label {
    width: 100pt;
    font-size: 8pt;
    line-height: 110%;
    font-weight: 700;
    display: table-cell;
    vertical-align: middle;
}

.grafic-baron .bar {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    height: 7mm;
    width: 100%;
}

.grafic-baron .bar .progres {
    position: absolute;
    height: 7mm;
    left: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 1;
    padding: 0 0.5mm;
    
    font-size: 8pt;
    font-weight: 700;
    line-height: 7mm;
    background-color: #B2B2B2;
}

.grafic-baron .bar .progres span {
    padding-left: 1mm;
}

.grafic-baron .bar .progres.RA {
    background-color: #8CD0EA;
}

.grafic-baron .bar .progres.ER {
    background-color: #EFDD8E;
}

.grafic-baron .bar .progres.SM {
    background-color: #CDDEA2;
}

.grafic-baron .bar .progres.AD {
    background-color: #E999C2;
}

.grafic-baron .bar .progres.GM {
    background-color: #F1626D;
}

.grafic-baron.no-label .scala-val,
.grafic-baron.no-label .scala-line,
.grafic-baron.no-label .grafic-baron-bottom-range {
    left: 0;
}

.grafic-baron.no-label .wrapper-grafice-baron {
    padding-left: 1pt;
}

.grafic-baron-list {
    position: relative;
    width: 100%;
    margin-bottom: 50pt;
}

.grafic-baron-list .scala-val {
    
    font-weight: 400;
    font-size: 6pt;
    line-height: 6pt;
    position: absolute;
    left: 119pt;
    right: 0;
    top: -4mm;
}

.grafic-baron-list .scala-val.bottom {
    top: auto;
    bottom: -2mm;
}

.grafic-baron-list .scala-val span {
    position: absolute;
    text-align: center;
    top: auto;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.grafic-baron-list .scala-val span:nth-child(1) {
    left: 0;
}

.grafic-baron-list .scala-val span:nth-child(2) {
    left: 10%;
}

.grafic-baron-list .scala-val span:nth-child(3) {
    left: 20%;
}

.grafic-baron-list .scala-val span:nth-child(4) {
    left: 30%;
}

.grafic-baron-list .scala-val span:nth-child(5) {
    left: 40%;
}

.grafic-baron-list .scala-val span:nth-child(6) {
    left: 50%;
}

.grafic-baron-list .scala-val span:nth-child(7) {
    left: 60%;
}

.grafic-baron-list .scala-val span:nth-child(8) {
    left: 70%;
}

.grafic-baron-list .scala-val span:nth-child(9) {
    left: 80%;
}

.grafic-baron-list .scala-val span:nth-child(10) {
    left: 90%;
}

.grafic-baron-list .scala-val span:nth-child(11) {
    right: -3.5mm;
}

.grafic-baron-list .scala-line {
    position: absolute;
    left: 119pt;
    right: 0;
    top: 0;
    bottom: 0;
}

.grafic-baron-list .scala-line span {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.grafic-baron-list .scala-line span:nth-child(1) {
    left: 0;
}

.grafic-baron-list .scala-line span:nth-child(2) {
    left: 10%;
}

.grafic-baron-list .scala-line span:nth-child(3) {
    left: 20%;
}

.grafic-baron-list .scala-line span:nth-child(4) {
    left: 30%;
}

.grafic-baron-list .scala-line span:nth-child(5) {
    left: 40%;
}

.grafic-baron-list .scala-line span:nth-child(6) {
    left: 50%;
}

.grafic-baron-list .scala-line span:nth-child(7) {
    left: 60%;
}

.grafic-baron-list .scala-line span:nth-child(8) {
    left: 70%;
}

.grafic-baron-list .scala-line span:nth-child(9) {
    left: 80%;
}

.grafic-baron-list .scala-line span:nth-child(10) {
    left: 90%;
}

.grafic-baron-list .scala-line span:nth-child(11) {
    right: 0;
}

.grafic-baron-list .scala-line span:after {
    display: block;
    content: '';
    border-left: 0.6pt dashed #000;
    left: 0;
    top: 0;
    -webkit-transform: translate(50%, 0);
    -moz-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    transform: translate(50%, 0);
    height: 100%;
}

.grafic-baron-list .scala-line .color-zone {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 40%;
    width: 20%;
    background-color: rgba(233, 151, 159, 0.2);
}

.grafic-baron-list .scala-line .grey-zone {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 40%;
    width: 20%;
    background-color: rgba(0, 0, 0, 0.04);
}

.grafic-baron-list .wrapper-grafice-baron {
    background-color: #F2F3F2;
    padding: 10pt 0 10pt 10pt;
}

.grafic-baron-list .table-bars {
    display: table;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    margin: 0 0 10pt;
}

.grafic-baron-list .table-bars:last-child {
    margin-bottom: 10pt;
}

.grafic-baron-list .grafic-baron-bar {
    display: table-row;
}

.grafic-baron-list .grafic-baron-bottom-range {
    position: absolute;
    bottom: -15pt;
    left: 119pt;
    right: 0;
}

.grafic-baron-list .grafic-baron-bottom-range .range {
    position: absolute;
    text-align: center;
    top: 0;
}

.grafic-baron-list .grafic-baron-bottom-range .range .border {
    height: 2mm;
    width: 100%;
    border-bottom: 1.1pt solid #707070;
    margin-bottom: 4pt;
    position: relative;
}

.grafic-baron-list .grafic-baron-bottom-range .range .border:before {
    position: absolute;
    left: 0;
    height: 2mm;
    width: 1.1pt;
    bottom: 0;
    background-color: #707070;
    content: '';
}

.grafic-baron-list .grafic-baron-bottom-range .range .border:after {
    position: absolute;
    right: 0;
    height: 2mm;
    width: 1.1pt;
    bottom: 0;
    background-color: #707070;
    content: '';
}

.grafic-baron-list .grafic-baron-bottom-range .range .name {
    
    font-size: 5pt;
    font-weight: 400;
    line-height: 110%;
    margin-bottom: 2pt;
    white-space: nowrap;
    text-align: center;
    color: #414042;
}

.grafic-baron-list .bar-label-cat {
    width: 110pt;
    font-size: 8pt;
    line-height: 110%;
    font-weight: 700;
    display: table-cell;
    vertical-align: middle;
}

.grafic-baron-list .bar-label {
    width: 110pt;
    font-size: 6pt;
    line-height: 110%;
    font-weight: 400;
    display: table-cell;
    vertical-align: middle;
}

.grafic-baron-list .bar {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    height: 5mm;
    width: 100%;
}

.grafic-baron-list .bar .progres {
    position: absolute;
    height: 4mm;
    left: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 1;
    padding: 0 0.5mm;
    
    font-size: 8pt;
    font-weight: 700;
    line-height: 4mm;
    background-color: #B2B2B2;
}

.grafic-baron-list .bar .progres span {
    padding-left: 1mm;
}

.grafic-baron-list .bar .progres.RA {
    background-color: #8CD0EA;
}

.grafic-baron-list .bar .progres.ER {
    background-color: #EFDD8E;
}

.grafic-baron-list .bar .progres.SM {
    background-color: #CDDEA2;
}

.grafic-baron-list .bar .progres.AD {
    background-color: #E999C2;
}

.grafic-baron-list .bar .progres.GM {
    background-color: #F1626D;
}

table.auto {
    table-layout: fixed;
    border-collapse: collapse;
}

table.auto td:first-child {
    white-space: nowrap;
}

.table-ajustari-raport-5 {
    border: 1pt solid #B2B2B2;
}

.table-ajustari-raport-5 .top td {
    background-color: #FFE1EB;
    border-bottom: 1pt solid #B2B2B2;
}

.table-ajustari-raport-5 td {
    padding: 3pt 5pt;
    font-size: 7pt;
}

.table-scoruri-brute-raport-5 td {
    padding: 3pt 3pt;
    font-size: 7pt;
    text-align: center;
}

.table-scoruri-brute-raport-5 td:first-child {
    text-align: left;
}

.w-50 {
    width: 50%;
}

.float-left {
    float: left;
}

table.copy {
    table-layout: fixed;
    border-collapse: collapse;
}

table.copy td:first-child {
    white-space: nowrap;
}

.copy-final {
    font-size: 7pt;
    line-height: 110%;
}

.copy-final p {
    font-size: 7pt;
    line-height: 110%;
    margin-bottom: 7pt;
    margin-top: 0;
}

.table-border-2 {
    border: 1px solid #000;
    border-bottom: none;
}

.table-border-2 .dark td {
    background-color: #393231;
    color: #fff;
}

.table-border-2 td {
    padding: 2mm 5mm;
}

.table-border-2 tr td {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

.table-border-2 tr td:last-child {
    border-right: none;
}

.table-border-2 .top td {
    border: none;
}

.cover-raport6 {
    background-color: #FFE0B1;
}

.cover-raport6 .wrapper-cover {
    color: #002D96;
}

/*.cover-raport6 .wrapper-cover h1,*/
/*.cover-raport6 .wrapper-cover h2,*/
/*.cover-raport6 .wrapper-cover h4 {*/
/*    color: #002D96;*/
/*}*/

.cover-raport6 .wrapper-cover h4 {
    font-weight: 400;
}

.cover-raport6 .wrapper-cover .subcover-title {
    font-weight: 400;
    font-size: 12pt;
    margin-top: 5pt;
}

.cover-raport6 .cover-img {
    width: 138mm !important;
    height: auto !important;
    left: auto;
    right: 0;
}

.table-aptitudini-mcseit {
    table-layout: fixed;
}

.table-aptitudini-mcseit td {
    padding: 5pt;
    background-color: #E1E2E2;
    font-size: 7pt;
    line-height: 120%;
    width: 25%;
}

.table-aptitudini-mcseit .top td {
    padding: 5pt;
    background-color: #414042;
    color: #fff;
    font-size: 7pt;
    line-height: 100%;
    text-align: center;
}

.grafic-baron .bar .interval-incredere {
    position: absolute;
    bottom: 9mm;
    height: 1.5pt;
    background-color: #707070;
}

.grafic-baron .bar .interval-incredere:before {
    content: '';
    display: block;
    background-color: #707070;
    width: 1.5pt;
    height: 4pt;
    position: absolute;
    left: 0;
    top: -1.5pt;
}

.grafic-baron .bar .interval-incredere:after {
    content: '';
    display: block;
    background-color: #707070;
    width: 1.5pt;
    height: 4pt;
    position: absolute;
    right: 0;
    top: -1.5pt;
}

.grafic-MSCEIT {
    position: relative;
    width: 100%;
    margin-bottom: 50pt;
}

.grafic-MSCEIT .scala-val {
    
    font-weight: 400;
    font-size: 6pt;
    line-height: 6pt;
    position: absolute;
    left: 109pt;
    right: 0;
    top: -4mm;
}

.grafic-MSCEIT .scala-val.bottom {
    top: auto;
    bottom: -2mm;
}

.grafic-MSCEIT .scala-val span {
    position: absolute;
    text-align: center;
    top: auto;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.grafic-MSCEIT .scala-val span:nth-child(1) {
    left: 0;
}

.grafic-MSCEIT .scala-val span:nth-child(2) {
    left: 10%;
}

.grafic-MSCEIT .scala-val span:nth-child(3) {
    left: 20%;
}

.grafic-MSCEIT .scala-val span:nth-child(4) {
    left: 30%;
}

.grafic-MSCEIT .scala-val span:nth-child(5) {
    left: 40%;
}

.grafic-MSCEIT .scala-val span:nth-child(6) {
    left: 50%;
}

.grafic-MSCEIT .scala-val span:nth-child(7) {
    left: 60%;
}

.grafic-MSCEIT .scala-val span:nth-child(8) {
    left: 70%;
}

.grafic-MSCEIT .scala-val span:nth-child(9) {
    left: 80%;
}

.grafic-MSCEIT .scala-val span:nth-child(10) {
    left: 90%;
}

.grafic-MSCEIT .scala-val span:nth-child(11) {
    right: -3.5mm;
}

.grafic-MSCEIT .scala-line {
    position: absolute;
    left: 109pt;
    right: 0;
    top: 0;
    bottom: 0;
}

.grafic-MSCEIT .scala-line span {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.grafic-MSCEIT .scala-line span:nth-child(1) {
    left: 0;
}

.grafic-MSCEIT .scala-line span:nth-child(2) {
    left: 10%;
}

.grafic-MSCEIT .scala-line span:nth-child(3) {
    left: 20%;
}

.grafic-MSCEIT .scala-line span:nth-child(4) {
    left: 30%;
}

.grafic-MSCEIT .scala-line span:nth-child(5) {
    left: 40%;
}

.grafic-MSCEIT .scala-line span:nth-child(6) {
    left: 50%;
}

.grafic-MSCEIT .scala-line span:nth-child(7) {
    left: 60%;
}

.grafic-MSCEIT .scala-line span:nth-child(8) {
    left: 70%;
}

.grafic-MSCEIT .scala-line span:nth-child(9) {
    left: 80%;
}

.grafic-MSCEIT .scala-line span:nth-child(10) {
    left: 90%;
}

.grafic-MSCEIT .scala-line span:nth-child(11) {
    right: 0;
}

.grafic-MSCEIT .scala-line span:after {
    display: block;
    content: '';
    border-left: 0.6pt dashed #000;
    left: 0;
    top: 0;
    -webkit-transform: translate(50%, 0);
    -moz-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    transform: translate(50%, 0);
    height: 100%;
}

.grafic-MSCEIT .scala-line .color-zone {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 40%;
    width: 20%;
    background-color: rgba(233, 151, 159, 0.2);
}

.grafic-MSCEIT .scala-line .grey-zone {
    position: absolute;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 40%;
    width: 20%;
    background-color: rgba(0, 0, 0, 0.04);
}

.grafic-MSCEIT .wrapper-grafice {
    background-color: #F2F3F2;
    padding: 10pt 0 10pt 10pt;
}

.grafic-MSCEIT .table-bars {
    display: table;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    margin: 10pt 0 20pt;
}

.grafic-MSCEIT .table-bars:last-child {
    margin-bottom: 10pt;
}

.grafic-MSCEIT .grafic-bar {
    display: table-row;
}

.grafic-MSCEIT .bottom-range {
    position: absolute;
    bottom: -15pt;
    left: 109pt;
    right: 0;
}

.grafic-MSCEIT .bottom-range .range {
    position: absolute;
    text-align: center;
    top: 0;
}

.grafic-MSCEIT .bottom-range .range .border {
    height: 2mm;
    width: 100%;
    border-bottom: 1pt solid #707070;
    margin-bottom: 4pt;
    position: relative;
}

.grafic-MSCEIT .bottom-range .range .border:before {
    position: absolute;
    left: 0;
    height: 2mm;
    width: 1pt;
    bottom: 0;
    background-color: #707070;
    content: '';
}

.grafic-MSCEIT .bottom-range .range .border:after {
    position: absolute;
    right: 0;
    height: 2mm;
    width: 1pt;
    bottom: 0;
    background-color: #707070;
    content: '';
}

.grafic-MSCEIT .bottom-range .range .name {
    
    font-size: 5pt;
    font-weight: 400;
    line-height: 110%;
    margin-bottom: 2pt;
    white-space: nowrap;
    text-align: center;
    color: #414042;
}

.grafic-MSCEIT .bar-label {
    width: 100pt;
    font-size: 7pt;
    line-height: 110%;
    font-weight: 700;
    display: table-cell;
    vertical-align: middle;
}

.grafic-MSCEIT .bar {
    display: table-cell;
    position: relative;
    vertical-align: middle;
    height: 7mm;
    width: 100%;
}

.grafic-MSCEIT .bar .progres {
    position: absolute;
    height: 7mm;
    left: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 1;
    padding: 0 0.5mm;
    
    font-size: 8pt;
    font-weight: 700;
    line-height: 7mm;
    background-color: #B2B2B2;
}

.grafic-MSCEIT .bar .progres span {
    padding-left: 1mm;
}

.grafic-MSCEIT .bar .interval-incredere {
    position: absolute;
    bottom: 9mm;
    height: 1.5pt;
    background-color: #707070;
}

.grafic-MSCEIT .bar .interval-incredere:before {
    content: '';
    display: block;
    background-color: #707070;
    width: 1.5pt;
    height: 4pt;
    position: absolute;
    left: 0;
    top: -1.5pt;
}

.grafic-MSCEIT .bar .interval-incredere:after {
    content: '';
    display: block;
    background-color: #707070;
    width: 1.5pt;
    height: 4pt;
    position: absolute;
    right: 0;
    top: -1.5pt;
}
.table-raspuns-itemi-raport6 {
    border: 1pt solid #000 !important;
}
.table-subsection {
    padding: 5mm 0;
}
.table-raspuns-itemi-raport6 td {
    padding: 3pt;
    font-size: 7pt;
    line-height: 7pt;
}
.simple-table {
    margin: 0 0 12pt 0;
    padding: 0;
    /*table-layout: fixed;*/
    border-collapse: collapse;
    position: relative;
    min-width: 500px;
}
.simple-table thead tr {
    margin: 0;
    padding: 0;
    border-bottom: 0.2pt solid #9A9999;
}
.simple-table thead tr td{
    padding: 5px 10px;
    font-weight: 700;
    font-size: 9.5pt;
}
.simple-table tbody tr {
    margin: 0;
    padding: 0;
    border-bottom: 0.2pt solid #9A9999;
}
.simple-table tbody tr:nth-child(odd) {
    background-color: #ECECEC;
}
.simple-table tbody tr td {
    padding: 5px 15px;
}
.simple-table .col-legend {
    font-size: 9.5pt;
    line-height: 10.5pt;
    font-weight: 700;
    position: relative;
    padding: 5px 1%;
}
.section-despre p.table-title {
    font-weight: 500;
    font-size: 14pt;
    line-height: 18pt;
    width: auto;
    margin: 0 0 11pt 0;
}
@media screen {
    .pagebreak {
        margin-bottom: 100px;
    }
}

@media print {
    .pagebreak {
        break-after: always;
    }
    .pagebreakbefore {
        break-before: always;
    }
    /* page-break-after works, as well */
}

.print-bar {
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.print-bar a {
    display: inline-block;
    border-radius: 15px;
    padding: 10px 50px;
    background-color: #002D96;
    color: #fff;
    
    font-size: 18px;
    font-weight: 300;
    text-decoration: none;
    margin: 0 10px;
}

@media screen and (max-width:989px) {
    ol {
        padding-inline-start: 16pt;
    }
    .wrapper-cover h1 {
        font-size: 20pt;
    }
    .wrapper-cover {
        padding: 0;
    }
    .cover-page {
        margin: -0;
        padding: 40px;
        position: relative;
    }
    .pagebreak {
        margin-bottom: 50px;
    }
    .mb-100 {
        margin-bottom: 50px;
    }
    .subsection {
        padding: 3mm;
    }
    .section-evaluare .subsection.marked {
        padding: 3mm;
    }
    .section-despre h1, .title-nivel, .title-nivel .td {
        margin: 5mm 0;
    }
    .subsection-content {
        padding-left: 16pt;
    }
    .section-despre h3{
        margin: 0 0 3mm;
    }
    .section-despre p {
        margin: 10px 0;
    }
    .print-bar a {
        padding: 10px 25px;
        text-align: center;
        font-size: 16px;
    }
    .cover-img.screen {
        display: none !important;
    }
    .cover-img.print {
        display: block !important;
        float: none;
        margin: 50px auto 0;
        width: 70%;
    }
    .cover-logo {
        width: 100%;
        max-width: 228pt;
    }
    .title-nivel tr:first-child td {
        white-space: normal;
    }
    .next-upgrade{
        left: 21%;
        top: 100%;
    }
    .chart .unit strong, .chart .unit {
        font-size: 7.6pt;
    }
    .tabel-note .col-legend .chart-note .name {
        white-space: pre-wrap;
    }
    .chart {
        transform: scale(0.9);
    }

}

@media screen and (max-width:599px) {
    .chart .nota{
        top: 7%;
    }
}